import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { Badge, Checkbox, ChevronDownIcon, ChevronUpIcon, PartnerCompendiumPanel } from '@labsavvyapp/components'
import styled from '@emotion/styled'

import { COMPENDIA_PANEL_IDENTIFIER, ORDER_SET_IDENTIFIER } from '../../constants'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import formatterPrice from '../../../../utils/formatter-price'

const TestColumnContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const CompendiaRowContainer = styled.div`
  display: grid;
  grid-template-columns: 130px 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  cursor: pointer;
`

const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
`

const PanelCode = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  font-weight: 700;
`

const Label = styled.label`
  font-size: 12px;
  cursor: pointer;
`

const PriceWrap = styled.span`
  display: flex;
  align-items: end;
`

const ButtonWrapper = styled.div`
  display: flex;
`

const FavoriteButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  padding: 0;
  line-height: 18px;
`

const AccordionButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-align: left;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  font-size: 12px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 3px 5px;

  &:hover {
    background-color: #f3dff0;
    border-radius: 5px;
  }
`

const Item = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  align-content: center;
  width: 100%;
`

const PrimaryHeaderItem = styled(Item)`
  height: 30px;
  background-color: #f3f3f3;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
`

const SecondaryHeaderItem = styled(Item)`
  height: 30px;
  background-color: #ebebeb;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
  font-weight: 700;
`

const ResultItem = styled(Item)`
  height: 30px;
  border-bottom: 1px solid #ebebeb;
  margin-left: 30px;
  padding: 0px 10px;
  width: calc(100% - 30px);
`

interface CustomTestsTransferListItemProps {
  data: any
  selected: boolean
  hideCheckbox?: boolean
  handleSelectedItems: React.Dispatch<React.SetStateAction<string[]>> | null
}

export const CustomTestTransferListItem = (props: CustomTestsTransferListItemProps) => {
  const businessLogic = useCreateLabOrderBusinessLogic()
  const packageListData = businessLogic?.packageListData ?? []
  const partnerCompendiaData = businessLogic?.partnerCompendiaData ?? []
  const selectedOrderSets = businessLogic?.selectedOrderSets ?? []
  const selectedCompendiaPanels = businessLogic?.selectedCompendiaPanels ?? []
  const handleSetFavorites = businessLogic?.handleSetFavorites ?? undefined

  const { data, selected, handleSelectedItems } = props
  const hideCheckbox = props.hideCheckbox ?? false
  const selectionType = data.value.includes(COMPENDIA_PANEL_IDENTIFIER) ? 'compendia' : 'orderset'

  const showPricing = businessLogic?.me?.partner?.config?.ordering?.enable_misc_fees ?? false

  const [isOpen, setIsOpen] = useState<boolean>(false)

  let projectPackageData
  let partnerCompendiaPanel: PartnerCompendiumPanel | undefined
  let retailPrice

  if (selectionType === 'orderset') {
    const orderSetId = data.value.split(ORDER_SET_IDENTIFIER)[0]
    projectPackageData =
      packageListData?.find((orderSetData) => orderSetData.package._id === orderSetId) ??
      selectedOrderSets?.find((orderSetData) => orderSetData.package._id === orderSetId)

    // ls_price | partner_price | retail_price
    retailPrice = projectPackageData?.package.retail_price
  } else if (selectionType === 'compendia') {
    const code = data.value.split(COMPENDIA_PANEL_IDENTIFIER)[0]
    partnerCompendiaPanel =
      partnerCompendiaData?.find((compendiaData) => compendiaData.code === code) ??
      selectedCompendiaPanels?.find((compendiaData) => compendiaData.code === code)

    // price
    // partner_pricing?
    //  - retail_price | contract_price
    retailPrice = partnerCompendiaPanel?.partner_pricing?.retail_price
  }

  const handleItemSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.currentTarget.checked
    handleSelectedItems &&
      handleSelectedItems((previousSelectedItems) => {
        return isSelected ? [...previousSelectedItems, data.value] : previousSelectedItems.filter((item) => item !== data.value)
      })
  }

  return (
    <TestColumnContainer>
      <CompendiaRowContainer>
        {!hideCheckbox ? (
          <StyledCheckbox
            checked={selected}
            onChange={handleItemSelection}
            label={<>{selectionType === 'compendia' ? <PanelCode>{data?.code}</PanelCode> : <Badge>{selectionType}</Badge>}</>}
          />
        ) : (
          <>
            {selectionType === 'compendia' ? (
              <PanelCode>{data?.code}</PanelCode>
            ) : (
              <span>
                <Badge>{selectionType}</Badge>
              </span>
            )}
          </>
        )}

        <ButtonWrapper>
          {selectionType === 'compendia' && (
            <FavoriteButton onClick={() => handleSetFavorites && handleSetFavorites(data?.value, businessLogic?.selectedLaboratory?._id || '', !partnerCompendiaPanel?.is_favorite || false)}>
              <Icon name={partnerCompendiaPanel?.is_favorite ? 'star' : 'star outline'} color='purple' />
            </FavoriteButton>
          )}

          <AccordionButton
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            {data.label}
            {showPricing ? (
              <PriceWrap>
                {formatterPrice(retailPrice)}
                {isOpen ? <ChevronUpIcon height={16} /> : <ChevronDownIcon height={16} />}
              </PriceWrap>
            ) : isOpen ? (
              <ChevronUpIcon height={16} />
            ) : (
              <ChevronDownIcon height={16} />
            )}
          </AccordionButton>
        </ButtonWrapper>
      </CompendiaRowContainer>

      {isOpen && selectionType === 'orderset' && projectPackageData && (
        <TestColumnContainer>
          {projectPackageData?.package.categories.map((category, index) => (
            <div key={`${category?.category?._id}_${index}`}>
              {category?.category?.name && (
                <PrimaryHeaderItem>
                  <span>{category?.category?.name}</span>
                </PrimaryHeaderItem>
              )}

              {category.panels.map((panel, index) => (
                <div key={`${panel?.code}_${index}`}>
                  <SecondaryHeaderItem>
                    <span>{panel.code}</span>
                    <span>{panel.name}</span>
                  </SecondaryHeaderItem>

                  {panel.tests.map((test) => (
                    <ResultItem key={test.result.code}>
                      <span>{test.result.code}</span>
                      <span>{test.result.name}</span>
                    </ResultItem>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </TestColumnContainer>
      )}

      {isOpen && selectionType === 'compendia' && partnerCompendiaPanel && (
        <TestColumnContainer>
          <SecondaryHeaderItem>
            <span>{partnerCompendiaPanel.code}</span>
            <span>{partnerCompendiaPanel.name}</span>
          </SecondaryHeaderItem>

          {partnerCompendiaPanel.results.map((result) => (
            <ResultItem>
              <span>{result.code}</span>
              <span>{result.name}</span>
            </ResultItem>
          ))}
        </TestColumnContainer>
      )}
    </TestColumnContainer>
  )
}
