import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Message } from 'semantic-ui-react'
import {
  ConfirmCancelCreateLabOrderModal,
  ConfirmChangeLaboratoryModal,
  CreateLabOrderControls,
  OrderSteps,
  PatientDetails,
  StepAskAtOrderEntry,
  StepReviewLabOrder,
  StepSelectICDCodes,
  StepSelectTests,
} from '../../components'
import { useCreateLabOrderBusinessLogic } from '../../logic'
import { CREATE_LAB_ORDER_STEPS, useClientViewModel } from '../../../../viewmodels'

const CreateLabContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`

const CreateLabContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const LabOrderFormContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 10fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`

const LabOrderFormContainerLeft = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const MessageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  padding-left: 10px;
`

export const CreateNewLabOrder = () => {
  const clientViewModel = useClientViewModel()
  const businessLogic = useCreateLabOrderBusinessLogic()
  const selectedPatient = businessLogic?.selectedPatient ?? null
  const activeStep = businessLogic?.activeStep ?? null
  const processLabOrderModal = businessLogic?.processLabOrderModal ?? null
  const processLabOrderProductModal = businessLogic?.processLabOrderProductModal ?? null

  const upsertFavoriteModal = businessLogic?.upsertFavoriteModal ?? null

  // If there is patientId in URL, load that user to the page
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const clientIdValue = searchParams.get('clientId') ?? ''
    businessLogic?.handleSelectedPatientChange(clientIdValue)
    const patientsPageVariables = clientViewModel?.patientsPageVariables ?? null
    if (clientIdValue) {
      clientViewModel?.handlePatientsPageVariablesChange({
        ...patientsPageVariables,
        filter: { _id: clientIdValue },
      })
    }
  }, [])

  return (
    <Container>
      <CreateLabContainer>
        <CreateLabOrderControls />
        <CreateLabContent>
          <LabOrderFormContainer>
            <LabOrderFormContainerLeft>
              {selectedPatient ? (
                <PatientDetails />
              ) : (
                <MessageContainer>
                  <Message warning>
                    <p>Please select a Patient to continue</p>
                  </Message>
                </MessageContainer>
              )}
            </LabOrderFormContainerLeft>

            <Container>
              <OrderSteps />
              {/* 1. Lab Selection */}
              {activeStep === CREATE_LAB_ORDER_STEPS.labs.key && <StepSelectTests />}

              {/* 2. AOE Questions */}
              {activeStep === CREATE_LAB_ORDER_STEPS.aoe.key && <StepAskAtOrderEntry />}

              {/* 3. ICD Selection */}
              {activeStep === CREATE_LAB_ORDER_STEPS.icd.key && <StepSelectICDCodes />}

              {/* 4. Review Information */}
              {activeStep === CREATE_LAB_ORDER_STEPS.review.key && <StepReviewLabOrder />}
            </Container>
          </LabOrderFormContainer>
        </CreateLabContent>
      </CreateLabContainer>

      {/* Lab Order Modals */}
      {processLabOrderModal}
      {processLabOrderProductModal}
      {upsertFavoriteModal}

      <ConfirmChangeLaboratoryModal />
      <ConfirmCancelCreateLabOrderModal />
    </Container>
  )
}
