import React, { useEffect, useState } from 'react'
import { CreateLabOrderBusinessLogicProvider } from './logic/CreateLabOrderBusinessLogicProvider'
import { CreateLabOrderView } from './CreateLabOrderView'
import { useSavingModals } from '@labsavvyapp/ui-components'
import {
  LabOrder,
  LabOrderInput,
  PartnerCompendiumPanel,
  ProjectPackage,
  Provider,
  TransferListData,
  useDebouncedValue,
  User,
} from '@labsavvyapp/components'
import {
  CREATE_LAB_ORDER_STEPS,
  CreateLabOrderSteps,
  ORDER_TYPES,
  useClientViewModel,
  useLabOrdersViewModel,
  useProvidersViewModel,
  useQueryGetPatientApi,
  useQueryGetProviderApi,
  useQueryListInsuranceApi,
  useQueryListPackagesApi,
  useQueryPartnerCompendiumApi,
  useQueryListPartnerMiscPricingApi,
} from '../../viewmodels'
import { ICDCode } from './logic'
import {
  checkCompendiaPanelAOEIfAnswered,
  checkIfOrderIsReady,
  checkOrdersetPanelAOEIfAnswered,
  generateAOEAnswers,
  generateBillType,
  generateCustomPackagePricing,
  generateICDCodes,
  generateInsuranceData,
  generateLabType,
  generateOrderCodes,
  generateOrderItemsData,
  generateOrderType,
  generateSelectedOrderSetsAndPanels,
  generateTransferListData,
  removeTransferListDuplicates,
  updateStepsOnTestSelection,
  generateMiscPricing,
} from './utils'
import { useMutation } from 'react-apollo'
import { CreateLabOrder, CreateLabOrderProduct } from '../../graphql/lab-orders/mutations'
import { UpsertFavorite } from '../../graphql/favorites/mutations'
import { useHistory, generatePath } from 'react-router-dom'
import { LAB_REPORTS, ORDERS_SENT } from '../../config/routes'
import { generateLabOrderName } from './utils/generateLabOrderName'
import { COMPENDIA_PANEL_IDENTIFIER } from '../LabOrder/constants'

interface ComponentProps extends React.ComponentProps<any> {
  me: User
}

export const CreateLabOrderBusinessLogic: React.FunctionComponent<ComponentProps> = (props: ComponentProps) => {
  const { me } = props
  const { push } = useHistory()

  const labOrderViewModel = useLabOrdersViewModel()
  const clientsViewModel = useClientViewModel()
  const providersViewModel = useProvidersViewModel()

  const [previousSelectedLaboratory, setPreviousSelectedLaboratory] = useState<Provider | null>(null)
  const [selectedPatientId, setSelectedPatientId] = useState<string>('')
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null)
  const [transferListData, setTransferListData] = useState<TransferListData>([[], []])
  const [activeStep, setActiveStep] = useState<CreateLabOrderSteps>(CREATE_LAB_ORDER_STEPS.labs.key)
  const [disabledSteps, setDisabledSteps] = useState<CreateLabOrderSteps[]>([
    CREATE_LAB_ORDER_STEPS.aoe.key,
    CREATE_LAB_ORDER_STEPS.icd.key,
    CREATE_LAB_ORDER_STEPS.review.key,
  ])
  const [completedSteps, setCompletedSteps] = useState<CreateLabOrderSteps[]>([])
  const [selectedIcdCodes, setSelectedIcdCodes] = useState<ICDCode[]>([])
  const [selectedOrderSets, setSelectedOrderSets] = useState<ProjectPackage[]>([])
  const [selectedCompendiaPanels, setSelectedCompendiaPanels] = useState<PartnerCompendiumPanel[]>([])
  const [isOrderReady, setIsOrderReady] = useState<boolean>(false)
  const [showConfirmSelectNewLabModal, setShowConfirmSelectNewLabModal] = useState<boolean>(false)
  const [showConfirmCancelCreateLabOrderModal, setShowConfirmCancelCreateLabOrderModal] = useState<boolean>(false)
  const [leftPanelSearch, setLeftPanelSearch] = useState<string>('')
  const [rightPanelSearch, setRightPanelSearch] = useState<string>('')
  const [selectedLeftPanelItems, setSelectedLeftPanelItems] = useState<string[]>([])
  const [selectedRightPanelItems, setSelectedRightPanelItems] = useState<string[]>([])
  const [debouncedLeftPanelSearch] = useDebouncedValue(leftPanelSearch, 200)
  const [areAllCompendiumPanelsWithAOEAnswered, setAreAllCompendiumPanelsWithAOEAnswered] = useState<boolean>(
    checkCompendiaPanelAOEIfAnswered(selectedCompendiaPanels),
  )
  const [areAllOrdersetPanelsWithAOEAnswered, setAreAllOrdersetPanelsWithAOEAnswered] = useState<boolean>(
    checkOrdersetPanelAOEIfAnswered(selectedOrderSets),
  )

  const { data: selectedPatient } = useQueryGetPatientApi(selectedPatientId)
  const { data: selectedLaboratory } = useQueryGetProviderApi(selectedProviderId)
  const {
    data: partnerCompendiaData,
    pageData: partnerCompendiaPageData,
    error: partnerCompendiaError,
    loading: partnerCompendiaLoading,
    refetch: refetchPartnerCompendiaData,
    fetchMore: fetchMorePartnerCompendiaData,
  } = useQueryPartnerCompendiumApi({
    filter: {
      search: debouncedLeftPanelSearch,
      partnerId: me?.partner?._id,
      providerId: selectedLaboratory?._id,
    },
    limit: 100,
  })

  const {
    data: packageListData,
    error: packageListError,
    loading: packageListLoading,
    refetch: refetchPackageListData,
    fetchMore: fetchMorePackageListData,
  } = useQueryListPackagesApi({
    providerId: selectedLaboratory?._id,
  })
  const { data: insuranceListData } = useQueryListInsuranceApi({
    patient_id: selectedPatient?._id,
  })

  const { data: miscPricingData } = useQueryListPartnerMiscPricingApi({
    partner_id: me?.partner?._id,
    provider_id: selectedLaboratory?._id,
  })

  const partnerMiscPricing = generateMiscPricing(miscPricingData)
  const patientListForDropdown = clientsViewModel?.patientListForDropdown ?? []
  const partnerProjectProvidersListForDropdown = providersViewModel?.partnerProjectProvidersListForDropdown ?? []
  const paymentMethodListForDropdown = labOrderViewModel?.paymentMethodListForDropdown ?? []
  const selectedPaymentMethod = paymentMethodListForDropdown.find((paymentMethod) => paymentMethod.value === selectedPaymentMethodId) ?? null

  // Setting Defaults (Selected Payment Method and Laboratory)
  useEffect(() => {
    if (paymentMethodListForDropdown && paymentMethodListForDropdown.length > 0 && !selectedPaymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodListForDropdown[0].value)
    }
    if (partnerProjectProvidersListForDropdown && partnerProjectProvidersListForDropdown.length > 0 && !selectedProviderId) {
      setSelectedProviderId(partnerProjectProvidersListForDropdown[0].value)
    }
  }, [patientListForDropdown, paymentMethodListForDropdown, partnerProjectProvidersListForDropdown])

  // Process data for Transfer list component
  useEffect(() => {
    // [ASAP-485] - add sorting by favorites
    partnerCompendiaData?.sort((a, b) => (a.is_favorite === b.is_favorite ? 0 : a.is_favorite ? -1 : 1))
    const testSelections = generateTransferListData(packageListData, partnerCompendiaData)
    setTransferListData((prevData) => [testSelections, prevData[1]])
  }, [packageListData, partnerCompendiaData])

  // Listen for Test Selections, then update stepper states
  useEffect(() => {
    updateStepsOnTestSelection(transferListData, setDisabledSteps, setCompletedSteps)
    removeTransferListDuplicates(transferListData, setTransferListData)
  }, [transferListData])

  // If there are selections, separating selected order sets and compendia panels data
  useEffect(() => {
    const { updatedSelectedPackages, updatedSelectedCompendiaPanels } = generateSelectedOrderSetsAndPanels(
      transferListData,
      packageListData,
      partnerCompendiaData,
      selectedOrderSets,
      selectedCompendiaPanels,
    )

    setSelectedOrderSets(updatedSelectedPackages)
    setSelectedCompendiaPanels(updatedSelectedCompendiaPanels)
  }, [transferListData[1]])

  // Listen for ICD Code selections, then update stepper states
  useEffect(() => {
    if (selectedIcdCodes.length > 0) {
      setDisabledSteps([])
      setCompletedSteps([CREATE_LAB_ORDER_STEPS.labs.key, CREATE_LAB_ORDER_STEPS.aoe.key, CREATE_LAB_ORDER_STEPS.icd.key])
    } else {
      // [ASAP-433] - make ICD optional
      // setDisabledSteps([CREATE_LAB_ORDER_STEPS.review.key])
      if (transferListData[1].length > 0) {
        setCompletedSteps([CREATE_LAB_ORDER_STEPS.labs.key, CREATE_LAB_ORDER_STEPS.aoe.key])
      }
    }
  }, [selectedIcdCodes])

  // Listen for Laboratory selection change
  useEffect(() => {
    if (previousSelectedLaboratory !== selectedLaboratory) {
      const changesFound = transferListData[1]?.length > 0 || selectedIcdCodes?.length > 0
      changesFound && toggleConfirmSelectNewLabModal()
    }
  }, [selectedLaboratory])

  // Listen for AOE answers
  useEffect(() => {
    if (selectedCompendiaPanels.length > 0) {
      handleAreAllCompendiumPanelsWithAOEAnsweredChange(checkCompendiaPanelAOEIfAnswered(selectedCompendiaPanels))
    }

    if (selectedOrderSets.length > 0) {
      handleAreAllOrdersetPanelsWithAOEAnsweredChange(checkOrdersetPanelAOEIfAnswered(selectedOrderSets))
    }
  }, [selectedCompendiaPanels, selectedOrderSets])

  useEffect(() => {
    if (activeStep === CREATE_LAB_ORDER_STEPS.aoe.key) {
      const areAllOrdersetPanelsAnswered = checkOrdersetPanelAOEIfAnswered(selectedOrderSets)
      const areAllCompendiumPanelsAnswered = checkCompendiaPanelAOEIfAnswered(selectedCompendiaPanels)

      if (areAllOrdersetPanelsAnswered && areAllCompendiumPanelsAnswered) {
        setDisabledSteps([])
        setCompletedSteps([CREATE_LAB_ORDER_STEPS.labs.key, CREATE_LAB_ORDER_STEPS.aoe.key])
      }
    }

    if (activeStep === CREATE_LAB_ORDER_STEPS.review.key) {
      setCompletedSteps([CREATE_LAB_ORDER_STEPS.labs.key, CREATE_LAB_ORDER_STEPS.aoe.key, CREATE_LAB_ORDER_STEPS.icd.key])
    }
  }, [selectedOrderSets, selectedCompendiaPanels, activeStep])

  // Check if order is ready to submit
  useEffect(() => {
    const isReady = checkIfOrderIsReady(selectedPatient, selectedPaymentMethod, selectedOrderSets, selectedCompendiaPanels, insuranceListData)
    setIsOrderReady(isReady)
  }, [selectedCompendiaPanels, selectedPatient])

  const handleSetFavorites = (order_code: string, provider_id: string, is_favorite: boolean) => {
    const orderCode = order_code.replace(COMPENDIA_PANEL_IDENTIFIER, '')
    processUpsertFavorite({ data: { order_code: orderCode, provider_id, is_favorite } })
  }

  const handleSelectedPatientChange = (patientId: string) => {
    setSelectedPatientId(patientId)
  }

  const handleSelectedProviderIdChange = (updatedProviderId: string) => {
    setSelectedProviderId(updatedProviderId)
  }

  const handlePreviousSelectedLaboratoryChange = (provider: Provider | null) => {
    setPreviousSelectedLaboratory(provider)
  }

  const handleSelectedProviderChange = (updatedProviderId: string) => {
    handlePreviousSelectedLaboratoryChange(selectedLaboratory)
    setSelectedProviderId(updatedProviderId)
  }

  const handleSelectedPaymentMethodChange = (paymentMethod: string) => {
    setSelectedPaymentMethodId(paymentMethod)
  }

  const handleTransferListDataChange = (updatedTransferListData: TransferListData) => {
    setTransferListData(updatedTransferListData)
  }

  const handleSelectedOrdersetPanelsChange = (updatedData: ProjectPackage[]) => {
    setSelectedOrderSets(updatedData)
  }

  const handleSelectedCompendiaPanelsChange = (updatedPanels: PartnerCompendiumPanel[]) => {
    setSelectedCompendiaPanels(updatedPanels)
  }

  const handleActiveStepChange = (updatedActiveStep: CreateLabOrderSteps) => {
    setActiveStep(updatedActiveStep)
  }

  const handleLeftPanelSearchChange = (search: string) => {
    setLeftPanelSearch(search)
  }

  const handleRightPanelSearchChange = (search: string) => {
    setRightPanelSearch(search)
  }

  const handleSelectedIcdCodesChange = (icd: ICDCode) => {
    const selectedCodes = selectedIcdCodes.some((item) => icd.code === item.code)
      ? selectedIcdCodes.filter((item) => icd.code !== item.code)
      : [...selectedIcdCodes, icd]

    setSelectedIcdCodes(selectedCodes)
  }

  const toggleConfirmSelectNewLabModal = () => {
    setShowConfirmSelectNewLabModal(!showConfirmSelectNewLabModal)
  }

  const toggleConfirmCancelCreateLabOrderModal = () => {
    setShowConfirmCancelCreateLabOrderModal(!showConfirmCancelCreateLabOrderModal)
  }

  const handleAreAllCompendiumPanelsWithAOEAnsweredChange = (areAnswered: boolean) => {
    setAreAllCompendiumPanelsWithAOEAnswered(areAnswered)
  }

  const handleAreAllOrdersetPanelsWithAOEAnsweredChange = (areAnswered: boolean) => {
    setAreAllOrdersetPanelsWithAOEAnswered(areAnswered)
  }

  const handleCancelOrder = () => {
    toggleConfirmCancelCreateLabOrderModal()
  }

  const handleSubmitOrder = () => {
    const payload: Partial<LabOrderInput> = {
      name: generateLabOrderName(selectedCompendiaPanels, selectedOrderSets),
      patient_id: selectedPatient?._id,
      provider_id: selectedLaboratory?._id,
      order_codes: generateOrderCodes(selectedCompendiaPanels, selectedOrderSets),
      insurance: generateInsuranceData(),
      order_items_data: generateOrderItemsData(selectedCompendiaPanels, selectedOrderSets),
      misc_pricing: partnerMiscPricing,
      order_type: generateOrderType(selectedPaymentMethod),
      bill_type: generateBillType(selectedPaymentMethod),
      lab_type: generateLabType(me),
      custom_package_pricing: generateCustomPackagePricing(selectedCompendiaPanels, selectedOrderSets),
      icd: generateICDCodes(selectedIcdCodes),
      aoe_answers: generateAOEAnswers(selectedCompendiaPanels, selectedOrderSets),
    }

    if (selectedPaymentMethod?.value === ORDER_TYPES['Bill to Patient'].value) {
      processLabOrderProduct({ data: payload })
    } else if (selectedPaymentMethod?.value === ORDER_TYPES['Bill to Client'].value) {
      processLabOrder({ data: payload })
    }
  }

  const [createLabOrder] = useMutation(CreateLabOrder)
  const [createLabOrderProduct] = useMutation(CreateLabOrderProduct)

  const [processLabOrderModal, { showModals: processLabOrder }] = useSavingModals({
    savingMessage: 'Your order is being submitted, please wait...',
    savedMessage: 'Your order has been submitted.',
    callback: async ({ data }: any) => {
      const { data: createLabOrderResponse } = await createLabOrder({
        variables: { data },
      })

      return createLabOrderResponse
    },
    onError: (error: any) => `${error}`,
    onSuccess: ({ createLabOrder }: { createLabOrder: LabOrder }) => {
      const labReportId = createLabOrder?._id
      push(
        generatePath(LAB_REPORTS.view, {
          labReportId: labReportId,
        }),
      )
    },
  })

  const [processLabOrderProductModal, { showModals: processLabOrderProduct }] = useSavingModals({
    savingMessage: 'Your order is sent to patient, please wait...',
    savedMessage: 'Your order has been sent to patient.',
    callback: async ({ data }: any) => {
      const { data: createLabOrderProductResponse } = await createLabOrderProduct({
        variables: { data },
      })
      return createLabOrderProductResponse
    },
    onError: (error: any) => `${error}`,
    onSuccess: () => {
      push(ORDERS_SENT.base)
    },
  })

  // [ASAP-485] - update or insert favorite
  const [upsertFavorite] = useMutation(UpsertFavorite)
  const [upsertFavoriteModal, { showModals: processUpsertFavorite }] = useSavingModals({
    savingMessage: 'Updating favorites, please wait...',
    savedMessage: 'Favorites has been updated.',
    callback: async ({ data }: any) => {
      const { data: response } = await upsertFavorite({
        variables: { data },
      })
      return response
    },
    onError: (error: any) => `${error}`,
    onSuccess: () => {
      // refetch partnerCompendium to update the listing
      refetchPartnerCompendiaData()
    },
  })

  return (
    <CreateLabOrderBusinessLogicProvider
      me={me}
      selectedPatient={selectedPatient}
      handleSelectedPatientChange={handleSelectedPatientChange}
      selectedLaboratory={selectedLaboratory}
      handleSelectedProviderChange={handleSelectedProviderChange}
      handleSelectedProviderIdChange={handleSelectedProviderIdChange}
      previousSelectedLaboratory={previousSelectedLaboratory}
      handlePreviousSelectedLaboratoryChange={handlePreviousSelectedLaboratoryChange}
      selectedPaymentMethod={selectedPaymentMethod}
      handleSelectedPaymentMethodChange={handleSelectedPaymentMethodChange}
      partnerCompendiaPageData={partnerCompendiaPageData}
      partnerCompendiaData={partnerCompendiaData}
      partnerCompendiaError={partnerCompendiaError}
      partnerCompendiaLoading={partnerCompendiaLoading}
      refetchPartnerCompendiaData={refetchPartnerCompendiaData}
      fetchMorePartnerCompendiaData={fetchMorePartnerCompendiaData}
      packageListData={packageListData}
      packageListError={packageListError}
      packageListLoading={packageListLoading}
      refetchPackageListData={refetchPackageListData}
      fetchMorePackageListData={fetchMorePackageListData}
      transferListData={transferListData}
      handleTransferListDataChange={handleTransferListDataChange}
      selectedOrderSets={selectedOrderSets}
      handleSelectedOrdersetPanelsChange={handleSelectedOrdersetPanelsChange}
      selectedCompendiaPanels={selectedCompendiaPanels}
      handleSelectedCompendiaPanelsChange={handleSelectedCompendiaPanelsChange}
      leftPanelSearch={leftPanelSearch}
      handleLeftPanelSearchChange={handleLeftPanelSearchChange}
      rightPanelSearch={rightPanelSearch}
      handleRightPanelSearchChange={handleRightPanelSearchChange}
      selectedLeftPanelItems={selectedLeftPanelItems}
      handleSelectedLeftPanelItemsChange={setSelectedLeftPanelItems}
      selectedRightPanelItems={selectedRightPanelItems}
      handleSelectedRightPanelItemsChange={setSelectedRightPanelItems}
      areAllCompendiumPanelsWithAOEAnswered={areAllCompendiumPanelsWithAOEAnswered}
      handleAreAllCompendiumPanelsWithAOEAnsweredChange={handleAreAllCompendiumPanelsWithAOEAnsweredChange}
      areAllOrdersetPanelsWithAOEAnswered={areAllOrdersetPanelsWithAOEAnswered}
      handleAreAllOrdersetPanelsWithAOEAnsweredChange={handleAreAllOrdersetPanelsWithAOEAnsweredChange}
      selectedIcdCodes={selectedIcdCodes}
      handleSelectedIcdCodesChange={handleSelectedIcdCodesChange}
      isOrderReady={isOrderReady}
      activeStep={activeStep}
      handleActiveStepChange={handleActiveStepChange}
      disabledSteps={disabledSteps}
      completedSteps={completedSteps}
      showConfirmSelectNewLabModal={showConfirmSelectNewLabModal}
      toggleConfirmSelectNewLabModal={toggleConfirmSelectNewLabModal}
      showConfirmCancelCreateLabOrderModal={showConfirmCancelCreateLabOrderModal}
      toggleConfirmCancelCreateLabOrderModal={toggleConfirmCancelCreateLabOrderModal}
      handleCancelOrder={handleCancelOrder}
      handleSubmitOrder={handleSubmitOrder}
      patientListForDropdown={patientListForDropdown}
      providerListForDropdown={partnerProjectProvidersListForDropdown}
      paymentMethodListForDropdown={paymentMethodListForDropdown}
      processLabOrderModal={processLabOrderModal}
      processLabOrderProductModal={processLabOrderProductModal}
      partnerMiscPricing={partnerMiscPricing}
      handleSetFavorites={handleSetFavorites}
      upsertFavoriteModal={upsertFavoriteModal}
    >
      <CreateLabOrderView />
    </CreateLabOrderBusinessLogicProvider>
  )
}
